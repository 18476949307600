/* eslint-disable @typescript-eslint/naming-convention, no-shadow */

import { Page, Section, SubSection } from './location-enums';

export interface AgeAtOnset {
  value: string;
  attributionSource: string;
}

export interface Feature {
  HPO_Name__c: string;
  HPO_Description__c: string;
  HPO_NAME_MAPPING__c: string;
  HPO_Name_map__c: string;
  Source_Curie__c: string;
  HPO_Category__c: string;
  Feature_System__c: string;
  HPO_Frequency__c: string;
  HPO_Method__c: string;
  HPO_Synonym__c: string;
  Feature_Type__c: string;
}

export interface Organization {
  id: string;
  name: string;
  url: string;
  providedServices: string[];
  services?: string[];
  languages?: string[];
  country?: string;
  expertDirectoryUrl?: string;
  patientRegistryUrl?: string;
}

export interface ContentDistribution {
  ContentDocumentId: string;
  ContentDownloadUrl: string;
  Id: string;
}

export interface DiagnosisItem {
  Type__c: DiagnosisItemType;
  Curie__c: string;
  Category__c: string;
}

export enum DiagnosisItemType {
  NEWBORN = 'NEWBORN',
  GTR = 'GTR',
  Procedure_EKG = 'Procedure_EKG',
  Imaging_MRI = 'Imaging_MRI',
  Imaging_PET = 'Imaging_PET',
  Imaging_CT = 'Imaging_CT',
  Imaging_Ultrasound = 'Imaging_Ultrasound',
  Imaging_Ultrasound_Fetal = 'Imaging_Ultrasound_Fetal',
  Imaging_Echocardiogram = 'Imaging_Echocardiogram',
  Procedure_EEG = 'Procedure_EEG',
  Procedure_EMG = 'Procedure_EMG',
  Procedure_NCV = 'Procedure_NCV',
  Procedure_PFT = 'Procedure_PFT',
  Lab = 'Lab',
}

export const DiagnosisItemTypePattern = {
  Imaging_Ultrasound:
    /^Imaging_Ultrasound$|^Imaging_Ultrasound;|; Imaging_Ultrasound; |; Imaging_Ultrasound$ /,
  Imaging_Ultrasound_Fetal: /Imaging_Ultrasound_Fetal/,
  Imaging_Echocardiogram: /Imaging_Echocardiogram/,
  Imaging_CT: /Imaging_CT/,
  Imaging_PET: /Imaging_PET/,
  Imaging_MRI: /Imaging_MRI/,
  Procedure_EKG: /Procedure_EKG/,
  Procedure_EEG: /Procedure_EEG/,
  Procedure_EMG: /Procedure_EMG/,
  Procedure_NCV: /Procedure_NCV/,
  Procedure_PFT: /Procedure_PFT/,
  Lab: /Lab/,
  GTR: /^GTR$/,
  NEWBORN: /^NEWBORN$/,
};

export const SymptomPatterns = {
  Lab: /^Lab.*$/,
  Procedure: /^Procedure.*$/,
  Imaging: /^Imaging.*$/,
};

export interface SalesforceAccountPayload {
  acct: SalesforceAccount;
  contentDistributionList: ContentDistribution[];
  tagAccountAssociationList: TagAccountAssociation[];
}

export interface TagAccountAssociation {
  Account__c: string;
  Id: string;
  Tag_Category__c: string;
  Tag_Name__c: string;
  attributes?: {
    type?: string;
    url?: string;
  };
}

export interface SalesforceAccount {
  Name: string;
  Web_Page_Tab__c: Page;
  Web_Page_Section__c: Section;
  Web_Page_Sub_Section__c: SubSection;
  Web_Page_Order__c: string;
  Country__c: string;
  Website: string;
  Additional_Posted_Information__c: string;
  Expert_Directory_URL__c?: string;
  Patient_Registry_URL__c?: string;
  Id: string;
}

export interface DiseaseDrug {
  Drug_Name__c: string;
  Modality__c: string;
  Approval_Status__c: string;
  Approval_Year__c: string;
  Source__c: string;
  Drug_Curie__c: string;
  GARD_Disease__c: string;
  Id: string;
}

export enum InfoSource {
  NORD = 'NORD',
  MedlinePlus = 'MedlinePlus',
  RareSource = 'RareSource',
  GeneReview = 'Gene Review',
  BiomedicalDataTranslator = 'Biomedical Data Translator',
}

export interface Gene {
  dzGeneAssociation: string;
  geneSymbol: string;
  dzGeneAssociationAttributionSource: string;
  geneType: string;
  geneCausal: boolean;
}

export enum Language {
  En = 'en_US',
  Es = 'es',
}

export enum AnimationState {
  show = 'show',
  right = 'right',
  left = 'left',
  hide = 'hide',
}

export interface Tag {
  Id: string;
  Tag_Category__c: string;
  Tag_Name__c: string;
}
