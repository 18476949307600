<div class="container-fluid pt-3">
    <div class="container-xxxl bg-white">
        <div class="row">
            <div class="col">
                <div *ngIf="!isNull">
                    <div class="title">{{ recommended.title }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ngb-accordion class="accordion-diag-med-team" [attr.id]="prefix">
                    <ngb-panel
                        *ngFor="let article of articlesDisplayed; let i = index"
                        [title]="article.title"
                        [attr.id]="prefix + '-' + i"
                        [ngClass]="{ active: currentSectionIndex === i }"
                    >
                        <ng-template ngbPanelContent class="bg-lightblue row">
                            <div class="col-12">
                                <img *ngIf="article.images[0].url" [src]="articleImagesPath + article.images[0].url + '-250.webp'" [alt]="article.images[0].altText" />
                                <div class="gard-align-middle d-inline text-link fs-18" embeddedHTML [innerHTML]="article.bodyText"></div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
