import { Component } from '@angular/core';

@Component({
    selector: 'rare-disease-day',
    templateUrl: './rare-disease-day.component.html',
    styleUrls: ['./rare-disease-day.component.scss'],
})
export class RareDiseaseDayComponent {
    REDIRECT_URL = 'https://registration.socio.events/e/rddnih2024';
    shouldShow = new Date() < new Date('2025-03-01');
}
