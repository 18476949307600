/* eslint-disable max-len, @typescript-eslint/no-explicit-any */

import { LanguageLabelKeys } from './labels';
import { MessageKey } from './types';

export const GRID_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const ABOUT_SECTIONS = {
  disease: {
    id: 'disease',
    description: 'Disease Information',
    hidden: false,
  },
  symptoms: {
    id: 'symptoms',
    description: 'Symptoms',
    hidden: true,
  },
  causes: {
    id: 'causes',
    description: 'Causes',
    hidden: true,
  },
  // nextSteps: {
  //     id: 'nextSteps',
  //     description: 'Next Steps',
  //     hidden: false,
  // },
  ageAtOnset: {
    id: 'age-at-onset',
    description: 'Age at onset',
    hidden: true,
  },

  diagnosticJourney: {
    id: 'diagnosticJourney',
    description: 'Diagnostic Journey',
    hidden: false,
  },

  findYourCommunity: {
    id: 'find-your-community',
    description: 'Find Your Community',
    hidden: false,
  },
  livingWithAccounts: {
    id: 'living-with-accounts',
    description: 'Living with accounts',
    hidden: true,
  },

  clinicalStudies: {
    id: 'clinical-studies',
    description: 'Participate in Research',
    hidden: false,
  },

  // medicalTeam: {
  //   id: 'medicalTeam',
  //   description: 'Your Diagnostic Team',
  //   hidden: false,
  // },

  workingWithYourDoctor: {
    id: 'workingWithYourDoctor',
    description: 'Appointments and Diagnostic Evaluations',
    hidden: true,
  },

  // careCoordination: {
  //   id: 'careCoordination',
  //   description: 'Care Coordination',
  //   hidden: true,
  // },

  // findingRareDiseaseExpert: {
  //   id: 'findingRareDiseaseExpert',
  //   description: 'Rare Disease Experts',
  //   hidden: false,
  // },

  // livingIntro: {
  //   id: 'livingIntro',
  //   description: 'Discover Resources',
  //   hidden: false,
  // },
  // patientsCaregivers: {
  //   id: 'patients',
  //   description: 'Patients and Caregivers',
  //   hidden: true,
  // },
  // expectations: {
  //   // TODO: Remove/Update this when section is replaced
  //   id: 'expectations',
  //   description: 'Living With a Rare Disease',
  //   hidden: true,
  // },
  patientResources: {
    id: 'patientResources',
    description: 'Patient Resources',
    hidden: true,
  },
  caregiverResources: {
    id: 'caregiverResources',
    description: 'Caregiver Resources',
    hidden: true,
  },
  decisions: {
    id: 'decisions',
    description: 'Resources for Your Health Care Decisions',
    hidden: true,
  },
  // learn: {
  //   id: 'learn',
  //   description: 'Learn about Research',
  //   hidden: false,
  // },
  // participate: {
  //   id: 'participate',
  //   description: 'Participate in Research',
  //   hidden: false,
  // },
  // stayInformed: {
  //   id: 'stayInformed',
  //   description: 'Stay Informed and Get Involved',
  //   hidden: false,
  // },
  // partner: {
  //   id: 'partner',
  //   description: 'Engaging in Research Efforts',
  //   hidden: false,
  // },
};

export const DISEASE_SECTIONS = {
  about: { link: '', description: 'About the Disease', sections: ABOUT_SECTIONS, show: true },
  diagnosis: {
    link: 'diagnosis',
    description: 'Getting a Diagnosis',
    show: true,
  },
  living: {
    link: 'living',
    description: 'Resources and Support',
    show: false,
  },
  research: { link: 'research', description: 'Research', show: false },
};

type SectionDictionary = Partial<Record<string, LanguageLabelKeys>>;

export const WEBSITE_SECTIONS: SectionDictionary = {
  home: 'home',
  diseases: 'diseases',
  disease: 'disease',
  about: 'about',
  contactUs: 'contact',
  privacyPolicy: 'privacyPolicy',
  vulnerability: 'vulnerabilityPolicy',
  oig: 'oig',
  foia: 'foia',
  accessibility: 'accessibility',
  disclaimer: 'disclaimer',
  resources: 'resources',
};

export const ACCOUNT_NAMES = {
  rdcrn: 'Rare Diseases Clinical Research Network (RDCRN) Studies',
  findRdcrn: 'Find RDCRN Clinical Research Studies',
};

export const ACCOUNT_TYPES = {
  diseaseSpecific: 'Disease-Specific',
  umbrella: 'Umbrella',
};

export const ACCOUNT_RECORD_TYPES = {
  patientAdvocacyGroup: 'Patient Advocacy Group',
};

export const SYMPTOM_FREQUENCIES = {
  always: 95,
  veryFrequent: 78,
  frequent: 46,
  occasional: 19,
  uncommon: 2,
  default: 0,
};

export const AGE_AT_ONSET = {
  allAges: 'all ages',
};

export const FEATURE_TYPES = {
  symptom: 'Symptom',
};

export const EXTERNAL_POPUP_TEXT_EN = `You are leaving the GARD website.

This external link provides additional information that is consistent with the intended purpose of the site. GARD cannot attest to the accuracy of a non-federal site.

Linking to a non-federal site does not constitute an endorsement by GARD or any of its employees of the sponsors or the information and products presented on the site. You will be subject to the destination site's privacy policy when you follow the link.
        `;

export const MESSAGE_KEYS = {
  searchOpen: new MessageKey('searchOpen'),
};

export const DISEASE_TAGS = {
  mitochondrial: 'Mitochondrial',
  cancer: 'Cancer',
  chromosomalAnomaly: 'Chromosomal Anomaly',
  genetics: 'Genetics',
  infectiousDisease: 'Infectious Disease',
  leukodystrophy: 'Leukodystrophy',
  lsysosomal: 'Lysosomal',
  infectiousEmbryofetopathy: 'Infectious Embryofetopathy',
  rareBacterialInfectiousDisease: 'Rare Bacterial Infectious Disease',
  rareMycosis: 'Rare mycosis',
  rareParasiticDisease: 'Rare Parasitic Disease',
  rareViralDisease: 'Rare Viral Disease',
  inheritedMetabolicDiseases: 'Inborn Errors of Metabolism',
  rareCongenitalInfectiousDiseases: 'Rare Congenital Infectious Diseases',
  rareParasiticInfectiousDiseases: 'Rare Parasitic Diseases',
  congenitalAbnormality: 'Congenital Abnormality',
  urogenitalDisorders: 'Urogenital Disorders',
  pulmonology: 'Pulmonology',
  neurology: 'Neurology',
  pediatrics: 'Pediatrics',
};

export const TAG_CATEGORIES = {
  diseaseCategory: 'Disease Category',
  epidemiology: 'Epidemiology',
  rare: 'Rare',
  account: 'account',
  specialist: 'specialist',
  cause: 'cause',
};

export const EXTERNAL_IDENTIFIERS = {
  orphanet: 'Orphanet',
};
