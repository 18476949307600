import { Component, Input } from '@angular/core';
import { ACCOUNT_RECORD_TYPES } from 'shared/constants';
import { Page, Section, SubSection } from 'shared/location-enums';
import { PATHS } from 'shared/paths';
import { Article, Account } from 'shared/types';
import { PatientAdvocacyGroup } from 'src/app/components/common/organizations/organizations-interfaces';
import { availablePatientAdvocacyGroups } from 'src/app/components/common/organizations/patient-advocacy-groups';
import { AccountService } from 'src/app/services/account.service';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'disease-multidisciplinary',
    templateUrl: './disease-multidisciplinary.component.html',
    styleUrls: ['./disease-multidisciplinary.component.scss'],
})
export class DiseaseMultidisciplinaryComponent {
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    public textHeader = 'Multidisciplinary';

    public imgPath = PATHS.articleImages;

    public titleKA: Article;
    public bodyKA: Article;

    public orgsWithExpertDirectory: PatientAdvocacyGroup[] = [];

    constructor(private articleService: ArticleService, private diseaseService: DiseaseService) {
        this.titleKA = this.articleService.getOne(Page.diagnosis, Section.careCenters, SubSection.sectionHeader, undefined, undefined, false);
        this.textHeader = this.titleKA.title;

        this.bodyKA = this.articleService.getOne(Page.diagnosis, Section.careCenters, SubSection.parent, undefined, undefined, false);

        // add replacement for disease name
        this.bodyKA.bodyText = this.bodyKA.bodyText.replace('${DiseaseName}', this.diseaseService.disease.name);
    }
}
