<div class="container-fluid main pb-5">
    <div *ngIf="isNull" class="container-xxxl pt-5 pt-md-5">
        <div class="row mt-4">
            <div class="col">
                <div class="left">
                    <header-h2 class="occurs-common-title" [theme]="theme" [fontWeight]="700" [textHeader]="mainArticle?.title"></header-h2>
                    <div class="occurs-common-under-text me-xl-5 fs-md-18" [innerHTML]="mainArticle?.bodyText"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isNull" class="container-xxxl pt-5 pt-md-5 mb-md-5">
        <div class="row mt-xl-5">
            <div class="col-xl-4">
                <div class="left">
                    <header-h2 class="occurs-common-title" [theme]="theme" [fontWeight]="700" [textHeader]="mainArticle?.title"></header-h2>
                    <div class="occurs-common-under-text me-xl-5 fs-md-18" [innerHTML]="mainArticle?.bodyText"></div>
                </div>
            </div>

            <div class="col-xl-8">
                <div class="mt-4 mt-xl-0 ms-xl-5">
                    <div class="d-block d-md-flex">
                        <div *ngFor="let age of ages; let i = index" class="gard-flex-fill-column d-flex d-md-block">
                            <div class="d-flex d-md-block age-wrap">
                                <div class="svg-circle-bg my-0 mx-auto" [ngClass]="age.active ? 'active' : 'inactive'">
                                    <i class="{{ age.icon }}" title="{{ age.title }}"></i>
                                    <div class="hori-bar d-none d-md-block" [ngClass]="{ highlighted: age.connected }" *ngIf="i < ages.length - 1"></div>
                                    <div class="vert-bar d-block d-md-none" [ngClass]="{ highlighted: age.connected }" *ngIf="i < ages.length - 1"></div>
                                </div>
                                <div class="under" [ngClass]="age.active ? 'active' : 'inactive'">
                                    <div class="under-title fs-8 fs-md-6">
                                        {{ age.title }}
                                        <span *ngIf="age.active" class="sr-only">Selected</span>
                                    </div>
                                    <div class="under-under-age-range fs-8 fs-md-18">{{ age.year }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-gray-center-line"></div>
                    </div>
                    <div *ngIf="!isNull && disease?.ageAtOnsetSnippet" class="lower-text mt-5 d-flex justify-content-center fs-md-18" [innerHtml]="onsetSnippetText"></div>
                    <div class="onset-footer" *ngIf="!isNull">
                        <div class="fs-8 fs-md-6 text-center pt-4 mt-4 mb-0 mt-xl-0">
                            This information comes from
                            <a class="text-link" [href]="orphanet" (click)="redirectToOrphanet($event)" target="_blank"> Orphanet <i class="icon-gard-open-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="background">
        <div class="circle-top-left"></div>
        <div class="circle-outline-top-left"></div>
        <div class="circle-outline-huge-left"></div>
        <div class="circle-outline-small-center"></div>
        <div class="circle-bottom-right-center"></div>
        <div class="circle-big-right"></div>
        <div class="circle-outline-right"></div>
    </div>
</div>
