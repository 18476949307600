<top-navigation></top-navigation>
<main-banner [disease]="disease"></main-banner>

<disease-at-a-glance [id]="sections.disease.id"></disease-at-a-glance>

<!-- What Causes This Disease? -->
<causes [id]="sections.causes.id" theme="maroon"></causes>

<!-- Can This Disease Be Passed Down From Parent to Child? -->
<inheritance theme="maroon"></inheritance>

<!-- At What Age Does This Disease Typically Appear? -->
<age-at-onset theme="blue"></age-at-onset>

<!-- Symptoms -->
<symptoms-section theme="white"></symptoms-section>

<!-- Diagnostic Journey -->
<diagnostic-journey [id]="sections.diagnosticJourney.id"></diagnostic-journey>

<!-- Your Diagnostic Team -->
<your-medical-team theme="maroon"></your-medical-team>

<!-- Your Diagnostic Team -- Accordions for Specialties -->
<your-medical-team-accordion theme="maroon"></your-medical-team-accordion>

<line-break-shadow-below></line-break-shadow-below>

<disease-multidisciplinary theme="blue"></disease-multidisciplinary>

<simple-horizontal-rule></simple-horizontal-rule>

<!-- Rare Disease Experts: How can you find a rare disease expert? -->
<rare-disease-expert theme="maroon"></rare-disease-expert>

<line-break-shadow-below></line-break-shadow-below>

<!-- Find Your Community -->
<advocacy-and-support-groups [id]="sections.findYourCommunity.id"></advocacy-and-support-groups>

<!-- PAG Accounts -->
<patient-organizations-accounts></patient-organizations-accounts>

<!-- Participating in Clinical Studies -->
<participating-in-clinical-studies [id]="sections.clinicalStudies.id"></participating-in-clinical-studies>

<!-- What Are Clinical Studies? -->
<clinical-studies></clinical-studies>

<!-- ClinicalTrials.gov -->
<clinical-trials-contact-us></clinical-trials-contact-us>

<div class="mb-5"></div>

<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
