import { Component, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { YourMedicalTeamAccordionComponent } from '../your-medical-team-accordion/your-medical-team-accordion.component';

@Component({
    selector: 'your-medical-team',
    templateUrl: './your-medical-team.component.html',
    styleUrls: ['./your-medical-team.component.scss'],
})
export class YourMedicalTeamComponent {
    public medTeam: Article;
    public pediatric: Article;
    public allOthers: Article;
    public allArticles: Article[] = [];
    public articlesMatchingTag: Article[] = [];
    public textHeader = 'Your Diagnostic Team';
    public isNull = false;

    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    constructor(diseaseService: DiseaseService, articleService: ArticleService) {
        this.allOthers = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.otherSpecialist)[0];

        // This for the body
        this.medTeam = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.parent, undefined, undefined, this.isNull)[0];
        if (this.medTeam) {
            this.medTeam.bodyText = this.medTeam.bodyText.replace('${DiseaseName}', diseaseService.disease.name);
        }

        // All needed for correct null
        this.allArticles = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.specialists);

        // filter out main article, insure images
        this.allArticles = YourMedicalTeamAccordionComponent.postFetchProcessArticles(this.allArticles);

        // filter by disease tags
        this.articlesMatchingTag = YourMedicalTeamAccordionComponent.getArticles(this.allArticles, diseaseService.disease);

        if (diseaseService?.disease?.tagsSpecialist?.includes('Pediatrics')) {
            const allKAs = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.parent, undefined, undefined, false);
            this.pediatric = allKAs.filter((a: Article) => a.tags.includes('Pediatrics'))[0];
        }

        // compute if null
        this.isNull = this.articlesMatchingTag.length === 0;
    }
}
