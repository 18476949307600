<ng-container *ngIf="shouldShow">
    <!-- lg and xl screen -->
    <div class="d-none d-lg-block container-fluid">
        <div class="d-flex">
            <div class="col-3">
                <img class="logo-img" src="/assets/RDD_2024_Event-Banner_1600x400px_WHITE_FV.png" alt="Rare Disease Day at NIH Logo" />
            </div>
            <div class="col-8 col-lg-7 registration-text">
                <span>Registration for this year's <b class="ps-1">Rare Disease Day at NIH</b> is now open!</span>
            </div>
            <div class="col-1 col-lg-2 registration-btn justify-content-end">
                <button class="btn btn-outline-blue reg-btn text-center text-xl-start fs-6 fw-500">
                    <a link class="" [href]="REDIRECT_URL" target="_blank" rel="noopener noreferrer"> Register Here </a>
                </button>
            </div>
        </div>
    </div>

    <!-- md screen -->
    <div class="d-none d-md-block d-lg-none container-fluid">
        <div class="d-flex">
            <div class="col-7">
                <img class="logo-img" src="/assets/RDD_2024_Event-Banner_1600x400px_WHITE_FV.png" alt="Rare Disease Day at NIH Logo" />
            </div>
            <div class="col-1"></div>
            <div class="col-4">
                <div class="registration-text text-center">
                    <span>Registration for this year's<b class="ps-1">Rare Disease Day at NIH</b> is now open!</span>
                </div>
                <div class="registration-btn justify-content-center pt-3">
                    <button class="btn btn-outline-blue py-2 ps-4 pe-4 text-center text-xl-start fs-8 fw-600">
                        <a link class="" [href]="REDIRECT_URL" target="_blank" rel="noopener noreferrer"> Register Here </a>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- sm screen -->
    <div class="d-block d-md-none container-fluid">
        <div class="d-flex">
            <div class="col-12 d-flex flex-column align-items-center">
                <img class="logo-img-sm" src="/assets/RDD_2024_Event-Banner_1600x400px_WHITE_FV.png" alt="Rare Disease Day at NIH Logo" />
                <div class="registration-text text-center">
                    <span class="fs-14 ps-4 pe-4">Registration for this year's<b class="ps-1">Rare Disease Day at NIH</b> is now open!</span>
                </div>
                <div class="registration-btn justify-content-center pt-3 pb-3">
                    <button class="btn btn-outline-blue py-2 ps-4 pe-4 text-center text-xl-start fs-8 fw-600">
                        <a link class="" [href]="REDIRECT_URL" target="_blank" rel="noopener noreferrer"> Register Here </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
