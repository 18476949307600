<div class="container-fluid bg-lightblue">
    <div class="container-xxxl disease-page-top-element-padding">
        <div class="d-flex row py-5">
            <div class="col-12 col-lg-6 left-side align-items-center">
                <app-bar-header [textHeader]="article.title" theme="blue"></app-bar-header>
                <span [innerHtml]="article.bodyText"></span>
            </div>

            <div class="d-none d-lg-flex col-lg-6 right-side">
                <div class="desktop-svg">
                    <img class="mb-3 rounded-8" [src]="imgUrl" alt="" />
                    <!-- <span class="info info-1">{{ title1 }}</span>
                    <div class="info info-2">{{ title2 }}</div>
                    <div class="info info-3">{{ title3 }}</div>
                    <div class="info info-4">{{ title4 }}</div> -->
                </div>
            </div>
            <div class="col-12 d-lg-none">
                <div class="mobile-svg">
                    <img class="mb-3 rounded-8" [src]="imgUrl" alt="" />
                    <!-- <div class="info info-1">{{ title1 }}</div>
                    <div class="info info-2">{{ title2 }}</div>
                    <div class="info info-3">{{ title3 }}</div>
                    <div class="info info-4">{{ title4 }}</div> -->
                </div>
            </div>
        </div>
    </div>
</div>
