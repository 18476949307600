<div class="bg-lightblue" *ngIf="tabs?.length">
    <div class="container-xxxl">
        <div class="row">
            <div class="col mt-5 mb-4">
                <app-bar-header *ngIf="sectionHeader" theme="blue" [textHeader]="sectionHeader"></app-bar-header>
            </div>
        </div>
    </div>
    <button-detail-list-larger [tabs]="tabs" [hideTabs]="true"></button-detail-list-larger>
</div>
