<footer class="footer d-flex flex-column align-items-center mt-5 white-link">
    <div class="d-flex flex-grow-1 items justify-content-lg-center px-4 px-lg-0">
        <div class="d-flex items-parent">
            <div class="mb-5 mb-lg-0">
                <div class="logo d-flex align-items-center">
                    <span class="fs-1 fw-500 m-0 p-0 me-2 lh-1">GARD</span>
                    <span class="fs-7 m-0 p-0">Genetic and Rare Diseases <br />Information Center</span>
                </div>

                <div class="text-white">
                    <p class="m-0 mb-1">PO Box 8126,</p>
                    <p class="m-0">Gaithersburg, MD 20898-8126</p>
                </div>
            </div>
        </div>

        <div class="d-flex items-parent">
            <div class="item me-3">
                <ul class="d-flex flex-column list-unstyled">
                    <li class="mb-3"><a [routerLink]="['/' + paths.diseases]" [innerText]="labels.diseases"></a></li>
                    <li class="mb-3"><a [routerLink]="'/' + paths.resources" [innerText]="labels.resources"></a></li>
                    <li class="mb-3"><a [routerLink]="'/' + paths.about" [innerText]="labels.about"></a></li>
                    <li><a [routerLink]="'/' + paths.contactUs" [innerText]="labels.contact"></a></li>
                </ul>
            </div>

            <div class="item">
                <ul class="d-flex flex-column list-unstyled">
                    <li class="mb-3">
                        <a [routerLink]="'/' + paths.privacyPolicy" [innerText]="labels.privacyPolicy"></a>
                    </li>
                    <li class="mb-3">
                        <a [routerLink]="'/' + paths.disclaimer" [innerText]="labels.disclaimer"></a>
                    </li>
                    <li class="mb-3">
                        <a [routerLink]="'/' + paths.accessibility" [innerText]="labels.accessibility"></a>
                    </li>
                    <li class="mb-3">
                        <a [routerLink]="'/' + paths.foia" [innerText]="labels.foia"></a>
                    </li>
                    <li>
                        <a link target="_blank" rel="noopener noreferrer" [attr.href]="footerLinks.oig">{{ labels.oig }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="bottom py-lg-4 w-100 px-4 px-lg-0 fs-8 fs-md-6">
        <ul class="d-flex flex-column flex-lg-row list-unstyled m-0 justify-content-center mb-lg-2">
            <li class="fw-bold pe-lg-4 mb-3 mb-lg-2">
                <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" target="_blank" class="text-white">HHS Vulnerability Disclosure</a>
            </li>
        </ul>
        <ul class="d-flex flex-column flex-lg-row list-unstyled m-0 justify-content-center mb-lg-2">
            <li class="fw-bold pe-lg-4 mb-3 mb-lg-2">
                <a href="https://www.hhs.gov/" target="_blank" class="text-white">U.S. Department of Health & Human Services</a>
            </li>
            <li class="fw-bold ps-lg-4 pe-lg-4 mb-3 mb-lg-2">
                <a href="https://www.nih.gov/" target="_blank" class="text-white">National Institutes of Health</a>
            </li>
            <li class="fw-bold ps-lg-4 mb-3 mb-lg-2">
                <a href="https://ncats.nih.gov/" target="_blank" class="text-white">National Center for Advancing Translation Sciences</a>
            </li>
        </ul>
        <p class="text-lg-center text-white m-0" [innerText]="labels.footerSlogan"></p>
    </div>
</footer>
