<div class="diagnostic-expert-wrapper container-fluid py-5">
    <div class="container-xxxl py-lg-4">
        <!-- Desktop -->
        <div class="desktop d-none d-xl-flex justify-content-between">
            <div class="margin-text">
                <app-bar-header [textHeader]="titleKA" theme="maroon"></app-bar-header>

                <div class="mt-2">
                    <header-h3 class="mb-4" [theme]="theme" fontWeight="700" [textHeader]="mainKA.title"></header-h3>

                    <p class="description fs-18 mb-4" [innerHTML]="mainKA.bodyText"></p>
                </div>

                <ng-container *ngIf="orgsWithExpertDirectory.length > 0">
                    <div class="link-list text-link fs-18" [innerHTML]="linkKA.bodyText"></div>

                    <ul class="mt-1">
                        <li *ngFor="let org of orgsWithExpertDirectory">
                            <a class="link-list text-link fs-18" [href]="org.expertDirectoryUrl"
                                >{{ org.name }}
                                <i class="icon-gard-open-link"></i>
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="orgsWithExpertDirectory.length === 0">
                    <div class="link-list text-link fs-18" [innerHTML]="nullKA.bodyText"></div>
                </ng-container>
            </div>
            <div>
                <img [src]="imgPath + mainKA.images[0]?.url + '-600.webp'" [alt]="mainKA.images[0]?.altText" />
            </div>
        </div>

        <!-- Mobile -->
        <div class="mobile d-flex d-xl-none flex-column">
            <app-bar-header [textHeader]="titleKA" theme="maroon"></app-bar-header>

            <div>
                <header-h3 class="mb-4" [theme]="theme" fontWeight="700" [textHeader]="mainKA.title"></header-h3>
            </div>

            <div class="mb-4 mx-auto">
                <img [src]="imgPath + mainKA.images[0]?.url + '-600.webp'" [alt]="mainKA.images[0]?.altText" />
            </div>

            <div>
                <div class="mt-2">
                    <p class="fs-6 mb-0" [innerHTML]="mainKA.bodyText"></p>
                </div>
            </div>

            <ng-container *ngIf="orgsWithExpertDirectory.length > 0">
                <div class="link-list text-link fs-6 mt-4" [innerHTML]="linkKA.bodyText"></div>

                <ul class="mt-1">
                    <li *ngFor="let org of orgsWithExpertDirectory">
                        <a class="link-list text-link fs-18" [href]="org.expertDirectoryUrl"
                            >{{ org.name }}
                            <i class="icon-gard-open-link"></i>
                        </a>
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="orgsWithExpertDirectory.length === 0">
                <div class="link-list text-link fs-6 mt-4" [innerHTML]="nullKA.bodyText"></div>
            </ng-container>
        </div>
    </div>
</div>
