import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';
import { randomId } from 'shared/utils/random-id';

@Component({
    selector: 'causes-tabs',
    templateUrl: './causes-tabs.component.html',
    styleUrls: ['./causes-tabs.component.scss'],
})
export class CausesTabsComponent implements OnInit {
    @ViewChildren('test') test: QueryList<ElementRef<HTMLSpanElement>>;
    @Input() articles: Article[];
    @Input() mainArticle: Article;
    @Input() subArticle?: Article;
    @Input() nullArticle: Article;
    @Input() isNull: boolean;

    @ViewChild('img') img: ElementRef<HTMLImageElement>;
    @ViewChild('clamp') clamp: ElementRef<HTMLDivElement>;
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    public mobileArticle: Article;
    public articleImagesPath = PATHS.articleImages;
    public prefix = randomId();
    public expanded = false;
    public activeTabIndex = 0;

    public imgMaxHeight = 400;
    public scrollHeight = 400;

    imageUrl: string;
    imageAlt: string;

    @HostListener('window:resize')
    onResize() {
        this.resize();
    }

    resize() {
        if (this.clamp) {
            // only after init
            const clamp = this.clamp.nativeElement;
            const firstChild = clamp.children[0];
            const firstChildChild = firstChild.children[0];
            const clampHeight = firstChildChild?.clientHeight;
            this.imgMaxHeight = clampHeight;
        } else {
            setTimeout(() => this.resize(), 5);
        }
    }

    ngOnInit(): void {
        this.resize();
        this.getImageUrl(this.articles[this.activeTabIndex]);
    }

    public switchTab(i: number) {
        this.activeTabIndex = i;
        this.getImageUrl(this.articles[this.activeTabIndex]);
    }

    public mobileSwitchTab(article: Article) {
        this.mobileArticle = article;
        this.getImageUrl(article);
    }

    getImageUrl(article: Article): void {
        if (article && Object.hasOwn(article, 'images') && article.images.length && article.images[0] && Object.hasOwn(article.images[0], 'url')) {
            const firstKaImg = article.images[0];
            const url = firstKaImg.url;
            this.imageUrl = this.articleImagesPath + url + '-400.webp';
            this.imageAlt = firstKaImg.altText;
        } else {
            this.imageUrl = undefined;
            this.imageAlt = undefined;
        }
    }
}
