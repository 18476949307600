import { Component, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { DISEASE_TAGS } from 'shared/constants';
import { Section as SEC, SubSection as SS, SubSubsection, Page as TAB } from 'shared/location-enums';
import { Article } from 'shared/types';
import { DiseaseService } from '../../../../services/disease.service';
import { CauseSectionButtons } from '../../../common/cause/cause-functions';

@Component({
    selector: 'causes',
    templateUrl: './causes.component.html',
    styleUrls: ['./causes.component.scss'],
})
export class CausesComponent {
    // top section
    subArticle: Article;
    topArticles: Article[] = [];
    topQuestions: Article[] = [];
    topNullArticle: Article;
    isNull = false;
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    constructor(diseaseService: DiseaseService, articleService: ArticleService) {
        // NULL
        this.isNull = CausesComponent.isNull(diseaseService);

        if (this.isNull) {
            this.topNullArticle = articleService.getOne(TAB.about, SEC.causes, SS.introduction, undefined, undefined, true);
        } else {
            // CauseSectionButtons
            // Get needed KAs
            const articles: Article[] = articleService.getArticles(TAB.about, SEC.causes, SS.introduction, undefined, false, false);
            const questions: Article[] = articleService.getArticles(TAB.about, SEC.causes, SS.causeExplanation, undefined, undefined, false);
            const articleGene = articleService.getOne(TAB.about, SEC.causes, SS.causeExplanation, undefined, undefined, undefined, SubSubsection.causalGene);
            this.topQuestions = CauseSectionButtons(diseaseService.disease, articles, questions, articleGene);

            // NEW Genetics KA
            const geneticsNewArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.genetics))[0];
            this.subArticle = geneticsNewArticle;
        }
    }

    static isNull(diseaseService: DiseaseService): boolean {
        let isNull = true;

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.mitochondrial)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.chromosomalAnomaly)) {
            isNull = false;
        }

        // if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.infectiousDisease)) {
        //   isNull = false;
        // }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.genetics)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.inheritedMetabolicDiseases)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.leukodystrophy)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.lsysosomal)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.infectiousEmbryofetopathy)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.rareBacterialInfectiousDisease)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.rareMycosis)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.rareParasiticDisease)) {
            isNull = false;
        }

        if (DiseaseService.doesDiseaseHaveDiseaseCategory(diseaseService.disease, DISEASE_TAGS.rareViralDisease)) {
            isNull = false;
        }

        return isNull;
    }
}
