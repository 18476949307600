import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { randomId } from 'shared/utils/random-id';
import { DiseaseService } from 'src/app/services/disease.service';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Page, Section, SubSection } from 'shared/location-enums';
import { Article, Disease, Image } from 'shared/types';

@Component({
    selector: 'your-medical-team-accordion',
    templateUrl: './your-medical-team-accordion.component.html',
    styleUrls: ['./your-medical-team-accordion.component.scss'],
})
export class YourMedicalTeamAccordionComponent implements OnInit {
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;
    public isNull = false;

    public prefix = randomId();
    public medTeamArticle: Article;
    public articlesDisplayed: Article[] = [];
    public currentSectionIndex: boolean | number = false;
    public articleImagesPath: string = PATHS.articleImages;
    public recommended: Article;

    public articlesMatchingTag: Article[] = [];
    public articlesMatchingTagInverse: Article[] = [];
    public allArticles: Article[] = [];

    constructor(articleService: ArticleService, diseaseService: DiseaseService) {
        // this is for the title of the list of specialists, if null, don't show
        this.recommended = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.taggedSpecialist)[0];
        if (this.recommended) {
            this.recommended.title = this.recommended.title.replace('${DiseaseName}', diseaseService.disease.name);
        }

        // Specialist section
        // get all articles
        this.allArticles = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.specialists);

        // filter out main article, insure images
        this.allArticles = YourMedicalTeamAccordionComponent.postFetchProcessArticles(this.allArticles);

        // filter by disease tags
        this.articlesMatchingTag = YourMedicalTeamAccordionComponent.getArticles(this.allArticles, diseaseService.disease);

        // compute if null
        this.isNull = this.articlesMatchingTag.length === 0;

        // Sort Alpha by title
        this.articlesMatchingTag.sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));

        // filter by disease tags - inverse
        this.articlesMatchingTagInverse = YourMedicalTeamAccordionComponent.getArticlesInverse(this.allArticles, this.articlesMatchingTag);

        // Sort Alpha by title
        this.articlesMatchingTagInverse.sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));
    }

    ngOnInit(): void {
        // select which group we are showing
        this.articlesDisplayed = this.articlesMatchingTag;
    }

    onClick(i: number) {
        this.currentSectionIndex = i === this.currentSectionIndex ? -1 : i;
    }

    static postFetchProcessArticles(allArticles: Article[]): Article[] {
        // filter out main article
        allArticles.map(article => {
            // eslint-disable-next-line no-prototype-builtins
            if (!article.hasOwnProperty('images')) {
                article.images = [];
            }

            if (article.images.length === 0) {
                article.images.push({ url: '', altText: '' } as Image);
            }

            return article;
        });

        return allArticles;
    }

    static isNull(articleService: ArticleService, disease: Disease): boolean {
        return YourMedicalTeamAccordionComponent.getArticles(articleService.getArticles(Page.diagnosis, Section.medicalTeam), disease).length <= 1;
    }

    static getArticles(allArticles: Article[], disease: Disease): Article[] {
        // sort by order, undefined after items with defined order
        allArticles.sort((a, b) => (a.order !== undefined ? a.order : Infinity) - (b.order !== undefined ? b.order : Infinity));

        // filter by disease tags
        return allArticles.filter(art => art.allPages || (art.tags && art.tags.some(tag => disease.tagsSpecialist.indexOf(tag) > -1)));
    }

    static getArticlesInverse(allArticles: Article[], articlesMatchingTag: Article[]): Article[] {
        const retArticles: Article[] = [];
        const articlesMatchingTagIds = articlesMatchingTag.map(a => a.id);
        allArticles.forEach((article: Article) => {
            if (!articlesMatchingTagIds.includes(article.id)) {
                retArticles.push(article);
            }
        });

        return retArticles;
    }
}
