import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Section, SubSection, Page } from 'shared/location-enums';
import { Article } from 'shared/types';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'diagnostic-journey',
    templateUrl: './diagnostic-journey.component.html',
    styleUrls: ['./diagnostic-journey.component.scss'],
})
export class DiagnosticJourneyComponent {
    public article: Article;
    public articlesInfographic: Article[] = [];
    public imgAltText = '';
    public assetsPath = PATHS.assets;

    public title1 = '';
    public title2 = '';
    public title3 = '';
    public title4 = '';

    imgUrl: string;

    constructor(private articleService: ArticleService, private diseaseService: DiseaseService) {
        this.articlesInfographic = this.articleService.getArticles(Page.diagnosis, Section.parent, SubSection.infographicText, undefined, undefined, false);

        if (this.articlesInfographic.length === 4) {
            this.title1 = this.articlesInfographic[0].title;
            this.title2 = this.articlesInfographic[1].title;
            this.title3 = this.articlesInfographic[2].title;
            this.title4 = this.articlesInfographic[3].title;
        }

        const articlesAll = this.articleService.getArticles(Page.diagnosis, Section.parent, undefined, undefined, undefined, false);

        const articlesActuallyUndefined = articlesAll.filter((a: Article) => a.subSection === undefined);

        this.article = articlesActuallyUndefined[0];

        // there is more than one match, need to merge KAs
        if (this.article && this.article.images && this.article.images.length) {
            this.imgUrl = PATHS.articleImages + this.article.images[0].url + '-800.webp';
        }
    }
}
